import { Box, Container } from '@mui/material'
import React from 'react'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import img1 from '../../../assets/flag.png';
import CardMedia from '@mui/material/CardMedia';

export default function Topbar() {


  return (



    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} minHeight={160}>
        <Grid sx={{display: { xs: 'none', md: 'flex' }}} xs display="flex" justifyContent="center" alignItems="center">
          <img style={{ width: "200px" ,}} src={img1} alt="" />
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="center">
          <h2 style={{ fontSize: "42px" ,textAlign:"center"}}>تجمع مستقلي هيئة التنسيق الوطنية</h2>
        </Grid>
        <Grid sx={{display: { xs: 'none', md: 'flex' }}} xs display="flex" justifyContent="center" alignItems="center">
          <img style={{ width: "200px" }} src={img1} alt="" />
        </Grid>
      </Grid>
    </Box>
  )
}
