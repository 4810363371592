import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import background from "../../../assets/palmyra2.png";

const titleStyles = {
  fontSize: "0.9rem", // Adjust the font size as needed
  fontWeight: "bold",
  right: 0, // Align to the right

  color: "black",
  whiteSpace: "normal", // Allows the text to wrap
  textAlign: "right", // Aligns the text to the right
  overflow: "hidden", // Prevent overflow
  textOverflow: "ellipsis", // Add ellipsis if text is too long
  minWidth: "100%",
};
const NewsCard = ({ title, date, imageUrl }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        cursor: "pointer",
        justifyContent: "flex-end",
        flex: "1",

        border: 0, // Ensures no border
        boxShadow: "none", // Removes any shadow effects
        marginTop: "1rem",
      }}
    >
      <Box sx={{ flexDirection: "column" }}>
        <CardContent
          sx={{
            flex: "1 0 auto",
          }}
        >
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            textAlign="right"
          >
            {date}
          </Typography>
          <Typography component="p" variant="h8" sx={titleStyles}>
            {title}
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{
          width: 160, // Fixed width in pixels
          height: 100, // Fixed height in pixels
          objectFit: "cover",
        }}
        image={imageUrl}
      />
    </Card>
  );
};
export default NewsCard;
