import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import data from "../../../assets/data.png";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import data1 from "../../../assets/data1.png";
import data2 from "../../../assets/data2.png";
import data3 from "../../../assets/data3.png";
import data4 from "../../../assets/data4.png";
import data5 from "../../../assets/data5.png";
import SectionTitle from "../SectionTItle/SectionTitle";

const Data = () => {
  return (
    <>
      <SectionTitle sectionName="بيانات" />

      <Box
        id="data"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* Hero card on the left side */}
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <HeroCard
              title="ألا تزال آفاق الاقتصاد العالمي في ارتفاع؟"
              category="بيانات"
              date="April 15, 2024"
              backgroundImage={data}
              size="75vh"
              paragraph="أنين الصحافة وسبل النجاة.. تقرير معهد رويترز يحذر من طوفان الذكاء الاصطناعي"
              categoryColor="#0A6522"
            />
          </Grid>

          {/* News cards stacked vertically on the right side */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="الذكاء الاصطناعي والفرز الوشيك للمؤثرين"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={data1}
            />
            <NewsCard
              title="هل يضعِف التنافس الألماني الاتحاد الأوروبي؟"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={data2}
            />
            <NewsCard
              title=" التقارب الأميركي - الصيني مطلوب عالميا"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={data3}
            />
            <NewsCard
              title="أعمال رصدت لحظات العار في تاريخ الإنسانية"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={data4}
            />
            <NewsCard
              title="الرجل الذي أطلق مارد الذرة وقتله الندم"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={data5} // Assuming you want another NewsCard here
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Data;
