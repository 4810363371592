import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import backgroundImage from "../../../assets/background.jpeg";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import newspaper from "../../../assets/newpaper.png";
import home from "../../../assets/home.png";
import palmyra from "../../../assets/palmyra2.png";
import SectionTitle from "../SectionTItle/SectionTitle";

function Politics() {
  return (
    <>
      <SectionTitle sectionName="سياسة" />
      <Box
        id="policy"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* First card on the left side */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <HeroCard
                title="خريطة التفاعلات الإقليمية ومستقبل النظام العربي"
                category="سياسة"
                date="April 15, 2024"
                backgroundImage={backgroundImage}
                size="70vh"
                paragraph="يُلاحظ مما سبق أن النظام العربي، وإن كان قد امتلك دائمًا آلياته الخاصة للتعافي من هزائمه وأزماته، إلا إن المدة المطلوبة لهذا التعافي في تزايد عبر الزمن؛ فلم يتطلب الأمر سوى عامين للتعافي من هزيمة 1948 بتوقيع معاهدة الدفاع العربي المشترك"
                categoryColor="#FF5733"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="إذا كانت فائدة التحليل في الجزء السابق هي الكشف عن عوامل الاستمرار"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={palmyra}
            />{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="إذا كانت فائدة التحليل في الجزء السابق هي الكشف عن عوامل الاستمرار"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={home}
            />{" "}
          </Grid>

          {/* Bottom card under the top two, spanning their combined width */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="إذا كانت فائدة التحليل في الجزء السابق هي الكشف عن عوامل الاستمرار"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={newspaper}
            />{" "}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Politics;
