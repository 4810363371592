import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeroCard from "../HeroCard/HeroCard";
import multimedia1 from "../../../assets/multi.png";
import multimedia2 from "../../../assets/multi2.png";
import SectionTitle from "../SectionTItle/SectionTitle";

const Multimedia = () => {
  return (
    <>
      <SectionTitle sectionName="ملتيميديا" />

      <Box
        id="multimedia"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          marginBottom: "2rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <HeroCard
              category="ملتميديا"
              backgroundImage={multimedia1}
              size="100vh"
              categoryColor="#0A6522"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HeroCard
              category="ملتميديا"
              backgroundImage={multimedia2}
              size="100vh"
              categoryColor="#0A6522"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Multimedia;
