import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import Davinci from "../../../assets/Davinci.png";
import Painting from "../../../assets/painting.png";
import Writer from "../../../assets/Writer.png";
import Character from "../../../assets/Character.png";
import Writer2 from "../../../assets/writer2.png";
import City from "../../../assets/city1.png";
import SectionTitle from "../SectionTItle/SectionTitle";
const Education = () => {
  return (
    <>
      <SectionTitle sectionName="ثقافة" />
      <Box
        id="culture"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* Left side with one HeroCard and two NewsCards */}
          <Grid item xs={12} md={6}>
            <HeroCard
              title="كيف صنعت رواية «أحدب نوتردام» من الكاتدرائية شهرة عالمية؟"
              category="الثقافة"
              date="د. أنور محمود زناتي- ٢٥ يناير ٢٠٢٤"
              backgroundImage={Painting}
              size="65vh"
              paragraph="بطل الرواية هو كوازيمودو، قارع الجرس الأحدب بشع المنظر، ينشأ كوازيمودو، وهو الاسم الذي أطلقه عليه رئيس الشمامسة كلود فرولو، ومعناه نصف إنسان، مع قس الكنيسة ليربيه، ويدربه ليكون قارع الأجراس في الكاتدرائية..."
              categoryColor="#FF5733"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <NewsCard
                  title="الواقعية السحرية .. بين أروقة الأدب وظلال التاريخ"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={Character}
                />
              </Grid>
              <Grid item xs={12}>
                <NewsCard
                  title="لماذا نقرأ بروست؟"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={Writer}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right side with one HeroCard and two NewsCards */}
          <Grid item xs={12} md={6}>
            <HeroCard
              title="لوحة «المعركة من أجل المعايير» ليوناردو دافنشي عبقري النهضة"
              category="الثقافة"
              date="د. أنور محمود زناتي- ٢٥ يناير ٢٠٢٤"
              backgroundImage={Davinci}
              size="65vh"
              paragraph="رسمت اللوحة على جدار غرفة المجلس الأعلى دي سينكويسينتو Salone dei Cinquecento (قاعة الخمسمائة) في قصر فيكيو Palazzo Vecchio المقر التاريخي للحكومة في فلورنسا، وأطلق عليها اسم "
              categoryColor="#FF5733"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <NewsCard
                  title="حوار الفكـر والذاكرة والزمان"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={Writer2}
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <NewsCard
                  title="هل عزلة الكتابة حالة دائمة أم فترة انتقاليَّة مؤقَّتة؟"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={City}
                />{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Education;
