import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import img1 from '../../../assets/flag.png';


const pages = ['اتجاهات نظرية', 'بيانات', 'حقوق وحريات', 'اقتصاد', 'سياسة', 'الدين والحياة', 'تحليل', 'رأي', 'ملتيميديا', 'مقابلات'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var d = new Date();
  var dayName = days[d.getDay()];

  const formattedToday =  dd + '/' + mm + '/' + yyyy;
  


  return (


    <Grid container spacing={2} columns={12}>
      <Grid xs={4}>
        <span>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField id="input-with-sx" label="بحث" variant="standard" />
      </Box>

        </span>
       
        
      </Grid>
      <Grid xs={4}>
        <div style={{ textAlign: "center" ,marginTop:"10px"}}>
          { 
        dayName+' ' + formattedToday
         }
        </div>
      </Grid>
      <Grid xs={4}>
        <AppBar sx={{ bgcolor: "white", direction: "rtl", color: "#000", boxShadow: 'none' }} position="static">


          <Container maxWidth="xl">
            <Toolbar disableGutters>



            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, mr: 1 }}>
          <img style={{ width: "50px" ,}} src={img1} alt="" />
          </Box>

            
             



            </Toolbar>
          </Container>
        </AppBar>
      </Grid>

    </Grid>


  );
}
export default ResponsiveAppBar;