import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import backgroundImage from "../../../assets/economy.png";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import economy1 from "../../../assets/economy1.png";
import economy2 from "../../../assets/economy2.png";
import economy3 from "../../../assets/economy3.png";
import economy4 from "../../../assets/economy4.png";
import economy5 from "../../../assets/economy5.png";

import home from "../../../assets/home.png";
import palmyra from "../../../assets/palmyra2.png";
import SectionTitle from "../SectionTItle/SectionTitle";

const Economy = () => {
  return (
    <>
      <SectionTitle sectionName="اقتصاد" />

      <Box
        id="economy"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* Hero card on the left side */}
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <HeroCard
              title="ألا تزال آفاق الاقتصاد العالمي في ارتفاع؟"
              category="اقتصاد"
              date="April 15, 2024"
              backgroundImage={backgroundImage}
              size="80vh"
              paragraph="في الولايات المتحدة، تستمر أغلب المؤشرات الاقتصادية (وإن لم يكن جميعها) في الظهور بشكل أقوى من المتوقع. وهو أمر يبعث على الارتياح"
              categoryColor="#0A6522"
            />
          </Grid>

          {/* News cards stacked vertically on the right side */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="الاقتصاد العالمي يواجه تحديات مُستمرة"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={economy1}
            />
            <NewsCard
              title="أزمة الديون المُستمرة في البلدان النامية"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={economy2}
            />
            <NewsCard
              title="الصين  السقوط  مستبعد"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={economy3}
            />
            <NewsCard
              title="أين يهبط الاقتصاد العالمي في عام 2024؟"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={economy4}
            />
            <NewsCard
              title="هل بلغ الاقتصاد الصيني ذروته؟"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={economy5} // Assuming you want another NewsCard here
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Economy;
