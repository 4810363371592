import React from "react";
import Container from "@mui/material/Container";
import { Grid, Box } from "@mui/material";

import backgroundImage from "../../../assets/background.jpeg";
import People from "../../../assets/people.png";
import Palmyra from "../../../assets/Palmyra.png";
import Dama from "../../../assets/dama.png";
import Nature from "../../../assets/nature.png";

import HeroCard from "../HeroCard/HeroCard";

export default function Hero() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "1rem",
        height: { sx: "auto", md: "100vh" },
      }}
    >
      <Grid container spacing={1}>
        {/* First card on the left side */}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <HeroCard
              title="التجديد الثقافي والتنمية.. ملاحظات حول التاريخ الثقافي السوري في عالم متغير "
              category="سياسة"
              date="April 15, 2024"
              backgroundImage={Palmyra}
              size="91vh"
              categoryColor="#FF5733" // Example of passing a dynamic color
              
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid container spacing={1}>
            {/* Top two cards on the right */}
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <HeroCard
                title="منذ نهاية القرن التاسع عشر، والسؤال الرئيس الذى يواجه العقل  العربى، لماذا تقدموا؟"
                category="اقتصاد"
                date="April 15, 2024"
                backgroundImage={People}
                size="45vh"
                categoryColor="#FF5733"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <HeroCard
                title="منذ نهاية القرن التاسع عشر، والسؤال الرئيس الذى يواجه العقل  العربى، لماذا تقدموا؟"
                category="الثقافة"
                date="April 15, 2024"
                backgroundImage={Dama}
                size="45vh"
                categoryColor="#FF5733"
              />
            </Grid>

            {/* Bottom card under the top two, spanning their combined width */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <HeroCard
                title="منذ نهاية القرن التاسع عشر، والسؤال الرئيس الذى يواجه العقل  العربى، لماذا تقدموا؟"
                category="الثقافة"
                date="April 15, 2024"
                backgroundImage={Nature}
                size="45vh"
                categoryColor="#FF5733"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
