import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box, Typography, Container } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

import Navbar1 from "../../Components/Home/Navbar/Navbar1";
import Navbar2 from "../../Components/Home/Navbar/Navbar2";
import Topbar from "../../Components/Home/TopBar/Topbar";
import Footer from "../../Components/Home/Footer/Footer";
import Stack from "@mui/material/Stack";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";
import img1 from "../../assets/data1.png";
import NewsCard from "../../Components/Home/NewsCard/NewsCard";
import economy1 from "../../assets/economy1.png";
import economy2 from "../../assets/economy2.png";
import economy3 from "../../assets/economy3.png";
import economy4 from "../../assets/economy4.png";
import economy5 from "../../assets/economy5.png";
import parse from "html-react-parser";

import axios from "axios";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const RootContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(1),
}));

const FullWidthImage = styled("img")({
  width: "100%",
  height: "80vh",
});

const Content = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: "white",
}));

// function Article() {
//   const [User, setUser] = useState();
//   const getArticle = () => {
//     axios
//       .get("https://www.tanaghomtech.com/magazine/public/api/article/1")
//       .then((response) => {
//         console.log(response.data.data);
//         const user = response.data.data;
//         setUser(user); // Assuming the data is an array of categories
//       })
//       .catch((error) => {
//         console.error(
//           "There has been a problem with your fetch operation:",
//           error
//         );
//       });
//   };
//   useEffect(() => {
//     getArticle();
//   }, []);
//   return (
//     <Container maxWidth="xl" sx={{ flexGrow: 1 }}>
//       <Navbar1 />
//       <Topbar />

//       <Navbar2 />
//       <Grid container spacing={2}>
//         <Grid xs={12} md={8}>
//           <Stack spacing={2}>
//             <Item
//               sx={{
//                 fontSize: "32px",
//                 fontWeight: 800,
//                 textAlign: "right",
//                 color: "#000",
//                 boxShadow: "none",
//               }}
//             ></Item>
//             <Item
//               sx={{
//                 fontSize: "16px",
//                 fontWeight: 500,
//                 textAlign: "right",
//                 color: "#000",
//                 boxShadow: "none",
//               }}
//             >
//               سياسة - 23/04/2024
//             </Item>
//             <Item
//               sx={{
//                 fontSize: "16px",
//                 fontWeight: 500,
//                 textAlign: "right",
//                 color: "#000",
//                 boxShadow: "none",
//               }}
//             >
//               <IconButton
//                 sx={{
//                   padding: "10px",
//                   borderRadius: "50%",
//                   backgroundColor: "red",
//                   color: "white",
//                   margin: "0 10px",
//                   ":hover": {
//                     bgcolor: "red", // theme.palette.primary.main
//                     color: "white",
//                   },
//                 }}
//                 color="primary"
//                 aria-label="add to shopping cart"
//               >
//                 <YouTubeIcon />
//               </IconButton>

//               <IconButton
//                 sx={{
//                   padding: "10px",
//                   borderRadius: "50%",
//                   backgroundColor: "green",
//                   color: "white",
//                   margin: "0 10px",
//                   ":hover": {
//                     bgcolor: "green", // theme.palette.primary.main
//                     color: "white",
//                   },
//                 }}
//               >
//                 {" "}
//                 <InstagramIcon />
//               </IconButton>
//               <IconButton
//                 sx={{
//                   padding: "10px",
//                   borderRadius: "50%",
//                   backgroundColor: "black",
//                   color: "white",
//                   margin: "0 10px",
//                   ":hover": {
//                     bgcolor: "black", // theme.palette.primary.main
//                     color: "white",
//                   },
//                 }}
//               >
//                 <FacebookIcon />
//               </IconButton>
//             </Item>

//             <Item
//               sx={{
//                 fontSize: "16px",
//                 fontWeight: 500,
//                 textAlign: "right",
//                 color: "#000",
//                 boxShadow: "none",
//               }}
//             >
//               {User && User.imageLink && (
//                 <img
//                   src={`https://www.tanaghomtech.com/magazine/storage/app/public/${User.imageLink}`}
//                   alt="User"
//                   style={{ width: "100%" }}
//                 />
//               )}
//             </Item>

//             <Item
//               sx={{
//                 fontSize: "16px",
//                 fontWeight: 500,
//                 textAlign: "right",
//                 color: "#000",
//                 boxShadow: "none",
//               }}
//             >
//               {parse(User.content)}
//             </Item>
//           </Stack>
//         </Grid>
//         <Grid xs={12} md={4}>
//           <NewsCard
//             title="الاقتصاد العالمي يواجه تحديات مُستمرة"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy1}
//           />
//           <NewsCard
//             title="أزمة الديون المُستمرة في البلدان النامية"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy2}
//           />
//           <NewsCard
//             title="الصين  السقوط  مستبعد"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy3}
//           />
//           <NewsCard
//             title="أين يهبط الاقتصاد العالمي في عام 2024؟"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy4}
//           />
//           <NewsCard
//             title="هل بلغ الاقتصاد الصيني ذروته؟"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy5} // Assuming you want another NewsCard here
//           />
//           <NewsCard
//             title="الاقتصاد العالمي يواجه تحديات مُستمرة"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy1}
//           />
//           <NewsCard
//             title="أزمة الديون المُستمرة في البلدان النامية"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy2}
//           />
//           <NewsCard
//             title="الصين  السقوط  مستبعد"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy3}
//           />
//           <NewsCard
//             title="أين يهبط الاقتصاد العالمي في عام 2024؟"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy4}
//           />
//           <NewsCard
//             title="هل بلغ الاقتصاد الصيني ذروته؟"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy5} // Assuming you want another NewsCard here
//           />
//           <NewsCard
//             title="أين يهبط الاقتصاد العالمي في عام 2024؟"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy4}
//           />
//           <NewsCard
//             title="هل بلغ الاقتصاد الصيني ذروته؟"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy5} // Assuming you want another NewsCard here
//           />
//           <NewsCard
//             title="هل بلغ الاقتصاد الصيني ذروته؟"
//             date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
//             imageUrl={economy5} // Assuming you want another NewsCard here
//           />
//         </Grid>
//       </Grid>
//       <Footer />
//     </Container>
//   );
// }

// export default Article;

const Article = () => {
  const location = useLocation();
  // const { id } = useParams();
  // const {showauthor }=useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    const getNew = () => {
      const queryParams = new URLSearchParams(location.search);
      // const idFromQuery = queryParams.get("id") || id;

      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/article/1?include=category,writer,country`
        )
        .then((response) => {
          setNewsData(response.data.data);
          console.log(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
          setIsLoading(false);
        });
    };

    getNew();
  }, [location.search]);

  if (isLoading) {
    return <div style={{ color: "#B5964D" }}>Loading...</div>;
  }

  const { title, author, created_at, content, imageLink,showauthor } = newsData;
  const formattedDate = new Date(created_at).toLocaleTimeString("default", {
    year: "numeric", month: "numeric", day: "numeric", time:"numeric"
  });

  return (
    <>
     
     <Container maxWidth="xl" sx={{ flexGrow: 1 }}>
       <Navbar1 />
       <Topbar />

       <Navbar2 />
      <Grid container spacing={2}>
        <Grid xs={12} md={8}>
          <Stack spacing={2}>
             <Item
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
                paddingTop:"50px"
              }}
            >

              {newsData.title}
            </Item>
            <Item
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
              }}
            >
              {formattedDate} | {newsData.writer.writerName} | {newsData.category.categoryName} |  {newsData.country.countryName}
            </Item>
            <Item
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
              }}
            >
              <IconButton
                sx={{
                  padding: "10px",
                  borderRadius: "50%",
                  backgroundColor: "red",
                  color: "white",
                  margin: "0 10px",
                  ":hover": {
                    bgcolor: "red", // theme.palette.primary.main
                    color: "white",
                  },
                }}
                color="primary"
                aria-label="add to shopping cart"
              >
                <YouTubeIcon />
              </IconButton>

              <IconButton
                sx={{
                  padding: "10px",
                  borderRadius: "50%",
                  backgroundColor: "green",
                  color: "white",
                  margin: "0 10px",
                  ":hover": {
                    bgcolor: "green", // theme.palette.primary.main
                    color: "white",
                  },
                }}
              >
                {" "}
                <InstagramIcon />
              </IconButton>
              <IconButton
                sx={{
                  padding: "10px",
                  borderRadius: "50%",
                  backgroundColor: "black",
                  color: "white",
                  margin: "0 10px",
                  ":hover": {
                    bgcolor: "black", // theme.palette.primary.main
                    color: "white",
                  },
                }}
              >
                <FacebookIcon />
              </IconButton>
            </Item>
           
            <Item
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
              }}
            >
             
                <img
                  src={`https://www.tanaghomtech.com/magazine/storage/app/public/${newsData.imageLink}`}
                  alt="User"
                  style={{ width: "100%" }}
                />
              
            </Item>

            <Item
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
              }}
            >
              {parse(newsData.content)}
            </Item>
          </Stack>
        </Grid>
        <Grid xs={12} md={4}>
        <Item
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
                paddingTop:"50px"
              }}
            >

               المقالات الأخيرة
            </Item>
          
          <NewsCard
            title="الاقتصاد العالمي يواجه تحديات مُستمرة"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy1}
          />
          <NewsCard
            title="أزمة الديون المُستمرة في البلدان النامية"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy2}
          />
          <NewsCard
            title="الصين  السقوط  مستبعد"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy3}
          />
          <NewsCard
            title="أين يهبط الاقتصاد العالمي في عام 2024؟"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy4}
          />
          <NewsCard
            title="هل بلغ الاقتصاد الصيني ذروته؟"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy5} // Assuming you want another NewsCard here
          />
          <NewsCard
            title="الاقتصاد العالمي يواجه تحديات مُستمرة"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy1}
          />
          <NewsCard
            title="أزمة الديون المُستمرة في البلدان النامية"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy2}
          />
          <NewsCard
            title="الصين  السقوط  مستبعد"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy3}
          />
          <NewsCard
            title="أين يهبط الاقتصاد العالمي في عام 2024؟"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy4}
          />
          <NewsCard
            title="هل بلغ الاقتصاد الصيني ذروته؟"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy5} // Assuming you want another NewsCard here
          />
          <NewsCard
            title="أين يهبط الاقتصاد العالمي في عام 2024؟"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy4}
          />
          <NewsCard
            title="هل بلغ الاقتصاد الصيني ذروته؟"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy5} // Assuming you want another NewsCard here
          />
          <NewsCard
            title="هل بلغ الاقتصاد الصيني ذروته؟"
            date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
            imageUrl={economy5} // Assuming you want another NewsCard here
          />
        </Grid>
      </Grid>
      <Footer />
    </Container>
    </>
  );
};

export default Article;
