import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import img1 from "../../../assets/flag.png";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import HeroCard from "../HeroCard/HeroCard";
import backgroundImage from "../../../assets/economy.png";
import firstBuilding from "../../../assets/building1.png";
import secondBuilding from "../../../assets/building2.png";
import thirdBuilding from "../../../assets/building3.png";
import fourthBuilding from "../../../assets/building4.png";
import SectionTitle from "../SectionTItle/SectionTitle";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  borderRadius: "0",
}));
function Rights() {
  return (
    <>
      <SectionTitle sectionName="حقوق وحريات" />
      <Grid
        id="rights"
        s
        sx={{ marginBottom: "3rem", height: { sx: "auto", md: "100vh" } }}
        container
        spacing={2}
      >
        <Grid xs={12} md={6}>
          {/* <Stack spacing={2}> */}
          {/* <Item sx={{ boxShadow: "none" }}> */}
          <HeroCard
            category="حقوق وحريات"
            backgroundImage={firstBuilding}
            paragraph=" الاستشراق الادبي قد اسهم في تغيير نظرة المستشرقين إلى الشرق، وتصويب
              الكثير من المفاهيم السابقة، اليت كان هدفها الهجوم على الثقافة
              العربية وتاريخها وتراثها."
            size="50vh"
            title="الشارقة الثقافية ترصد القصة التشكيلية والمشروع السردي الجديد"
            categoryColor="#000"
            categoryFontColor="#fff"
            //   backgroundParagaphColor="#f30000"
            paragraphWidth="70%"
          />
          {/* </Item>{" "} */}
          {/* <Item
            sx={{
              boxShadow: "none",
              color: "black",
              textAlign: "right",
              fontSize: "16px",
            }}
          ></Item>
        </Stack> */}
        </Grid>
        <Grid xs={12} md={6}>
          {/* <Stack spacing={2}>
          <Item sx={{ boxShadow: "none" }}> */}
          <HeroCard
            category="حقوق وحريات"
            backgroundImage={secondBuilding}
            paragraph="ونقرأ في باب “فن. وتر. ريشة” أيضا موضوعات تثير آخر القضايا المتعلقة بالفن التشكيلي والموسيقى ومما نقرأ “هدى العمر تمزج بين الكلمة واللون"
            size="50vh"
            title="فن نبيل يحمل قيما سامية"
            categoryColor="#000"
            categoryFontColor="#fff"
            //   backgroundParagaphColor="#f30000"
            paragraphWidth="70%"
          />
          {/* </Item>{" "}
          <Item
            sx={{
              boxShadow: "none",
              color: "black",
              textAlign: "right",
              fontSize: "16px",
            }}
          >
            ونقرأ في باب “فن. وتر. ريشة” أيضا موضوعات تثير آخر القضايا المتعلقة
            بالفن التشكيلي والموسيقى ومما نقرأ “هدى العمر تمزج بين الكلمة
            واللون”{" "}
          </Item>
        </Stack> */}
        </Grid>
        <Grid xs={12} md={6}>
          {/* <Stack spacing={2}>
          <Item sx={{ boxShadow: "none" }}> */}
          <HeroCard
            category="حقوق وحريات"
            backgroundImage={thirdBuilding}
            title="تاريخ الأفكار تاريخ ترحال وهجرة لا إقامة"
            size="50vh"
            paragraph=" حماية التراث ليست مجرد ترف أو فعلا ثانويا، فأي أمة أيا كانت هي في
              جوهرها تاريخها وتراثها، وليس التراث عناصر جامدة بل يمكن تحويله إلى
              وسيلة تنمي"
            categoryColor="#000"
            categoryFontColor="#fff"
            //   backgroundParagaphColor="#f30000"
            paragraphWidth="70%"
          />
          {/* </Item>{" "}
          <Item
            sx={{
              boxShadow: "none",
              color: "black",
              textAlign: "right",
              fontSize: "16px",
            }}
          >
            حماية التراث ليست مجرد ترف أو فعلا ثانويا، فأي أمة أيا كانت هي في
            جوهرها تاريخها وتراثها، وليس التراث عناصر جامدة بل يمكن تحويله إلى
            وسيلة تنمي{" "}
          </Item>
        </Stack> */}
        </Grid>
        <Grid xs={12} md={6}>
          <HeroCard
            category="حقوق وحريات"
            backgroundImage={fourthBuilding}
            title="العرب مطالبون بحماية تراثهم كي لا يلقى مصير أوبار"
            size="50vh"
            paragraph="النص الأدبي ملغز في ولادته، تعتمل في نسج خلاياه عوامل لا يدرك كنهها إلا من كابد أوجاعها، وقد يعسر القبض عليها وتفسيرها. النص بين الفكرة والإنجاز"
            categoryColor="#000"
            categoryFontColor="#fff"
            // backgroundParagaphColor="#f30000"
            paragraphWidth="70%"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Rights;
