import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import theoriticalTrends from "../../../assets/theoriticaltrends.png";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import politics from "../../../assets/theoriticaltrends1.png";
import war from "../../../assets/theoriticaltrends2.png";
import novel from "../../../assets/theoriticaltrends3.png";
import SectionTitle from "../SectionTItle/SectionTitle";

function TheoreticalTrends() {
  return (
    <>
      <SectionTitle sectionName="اتجاهات نظرية" />

      <Box
        id="trends"
        sx={{
          flexGrow: 1,
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* First card on the left side */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <HeroCard
                title="خطاب العالمية الغربية وما بعد الاستشراق"
                category="اتجاهات نظرية"
                date="April 15, 2024"
                backgroundImage={theoriticalTrends}
                size="70vh"
                categoryColor="#FF5733"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="واشنطن تعود إلى موازنتها بين تركيا واليونان"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={politics}
            />{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="من يريد الحرب في المنطقة؟"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={war}
            />{" "}
          </Grid>

          {/* Bottom card under the top two, spanning their combined width */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="الرواية في مصر.. إمبراطورية الفوضى والتفاهة والمال الأسود"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={novel}
            />{" "}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TheoreticalTrends;
