import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import pyramid from "../../../assets/pyramid.png";
import digol from "../../../assets/digol.png";
import mala from "../../../assets/mala.png";
import boat from "../../../assets/boat.png";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import SectionTitle from "../SectionTItle/SectionTitle";

const Religion = () => {
  return (
    <>
      <SectionTitle sectionName="الدين والحياة" />

      <Box
        id="religion"
        sx={{
          flexGrow: 1,
          marginTop: "2rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* First card on the left side */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <HeroCard
                title="مصر.. دليل “جديد” على طريقة بناء الهرم الأكبر"
                category="الدين والحياة"
                date="April 15, 2024"
                backgroundImage={pyramid}
                size="70vh"
                categoryColor="#FF5733"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="كيف تحولت حياة شارل ديغول من جندي فرنسي لزعيم منفي؟"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={digol}
            />{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="مالا تعرفه عن منسا موسى أغني ملوك العالم"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={mala}
            />{" "}
          </Grid>

          {/* Bottom card under the top two, spanning their combined width */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title="الحداثة- ما بعد الحداثة- بعد ما بعد الحداثة"
              date="أ. د. أحمد يوسف أحمد-٢٥ يناير ٢٠٢٤"
              imageUrl={boat}
            />{" "}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Religion;
