import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import Vangogh from "../../../assets/Vangogh.png";
import Dantie from "../../../assets//Danti.png";
import paintig1 from "../../../assets/p1.png";
import paintig2 from "../../../assets/p2.png";
import paintig3 from "../../../assets/p3.png";
import paintig4 from "../../../assets/p4.png";
import SectionTitle from "../SectionTItle/SectionTitle";

const Interview = () => {
  return (
    <>
      <SectionTitle sectionName="مقابلات" />

      <Box
        id="interviews"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* Left side with one HeroCard and two NewsCards */}
          <Grid item xs={12} md={6}>
            <HeroCard
              title="عزيزنا فينسنت فان جوغ"
              category="مقابلات"
              date="د. أنور محمود زناتي- ٢٥ يناير ٢٠٢٤"
              backgroundImage={Vangogh}
              size="65vh"
              paragraph="في ظل الظروف المعقدة مع نهايات القرن التاسع عشر والثورة الصناعية كانت في اوجها ولد وعاش بطلنا وعزيزنا فينسنت فان جوغ حاصرته الافكار المثالية التي نشأ وترعرع في ظلها  , محملاً بافكار وتعاليم دينية صارمة"
              categoryColor="#FF5733"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <NewsCard
                  title="إدوارد هوبر، فنان التباعد الاجتماعي"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={paintig1}
                />
              </Grid>
              <Grid item xs={12}>
                <NewsCard
                  title="الرؤية المزدوجة لعنصر الزمن"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={paintig2}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right side with one HeroCard and two NewsCards */}
          <Grid item xs={12} md={6}>
            <HeroCard
              title="الكوميديا الإلهيَّة لدانتي"
              category="مقابلات"
              date="د. أنور محمود زناتي- ٢٥ يناير ٢٠٢٤"
              backgroundImage={Dantie}
              size="65vh"
              paragraph=" «الكوميديا الإلهية» بأنها أكثر الخيال القروسطيّ تألّقًا. تُعدّ عبقريّةَ التعبير في نظرةٍ ولَّت للعالم، ليس العالمَ الحديث، بل من عصرٍ رأى كلَّ شيءٍ في علاقته مع الحقيقة الأسمَى التي هي الله "
              categoryColor="#FF5733"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <NewsCard
                  title="النفي خارج فضاء الأحلام"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={paintig3}
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <NewsCard
                  title="لِمَ تحتاج الفلسفة للأساطير؟"
                  date="د. محمد الشحات - ٢٥ يناير ٢٠٢٤"
                  imageUrl={paintig4}
                />{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Interview;
